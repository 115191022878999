import {onDomReady} from "../../components/dynamic/observer";
import Jax from "../../components/jax/jax";

class ReviewFilter {
    constructor(filter, reviewsElement) {
        this.element = filter;
        this.form = filter.querySelector('form');
        this.list = reviewsElement

        this.bind();
    }

    bind() {
        this.bindInputs();
    }

    bindInputs() {
        this.element.querySelectorAll('input[type="radio"], select').forEach((input) => {
           input.addEventListener('change', (e) => {
               this.request();
           });
        });
    }

    request() {
        const jax = new Jax(this.form.getAttribute('action'), 'GET');
        jax.send(new FormData(this.form)).then((html) => {
            this.replace(html);
        });
    }

    replace(html) {
        const page = document.createElement('div');
        page.innerHTML = html;

        const newContent = page.querySelector('[data-review-list]');
        this.list.innerHTML = newContent.innerHTML;
        document.dispatchEvent(new Event('DOMContentMutated'));
    }
}

onDomReady(() => {
    const filterElement = document.querySelector('[data-review-filter]');
    const reviewsElement = document.querySelector('[data-review-list]');
    if (filterElement && reviewsElement) {
        new ReviewFilter(filterElement, reviewsElement);
    }
})