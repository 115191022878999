import { onDomReady } from '../../components/dynamic/observer';
import timeoutDelay from '../../components/dynamic/timeout-delay';

class Header {
  constructor(element) {
    this.header = element;
    this.outer = element.querySelector('[data-header-outer]');
    this.hamburger = element.querySelector('[data-hamburger]');
    this.isOpened = false;
    this.currentOffsetHeader = 0;
    this.lastScrollPosition = 0;
    this.currentHeaderHeight = 55;
    this.isMobile = null;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    this.setLastScrollPosition(scrollTop);

    this.defineMobile();
    this.defineHeaderHeight();

    this.bind();
  }

  bind() {
    this.bindScroll();
    // this.bindHamburger();
    this.bindResize();
    this.bindHeaderEvents();
  }

  bindResize() {
    const instance = this;
    window.addEventListener('resize', () => {
      instance.defineMobile();
      instance.defineHeaderHeight();
    });
  }

  bindHeaderEvents() {
    const instance = this;
    this.header.addEventListener('ToTop', (e) => {
      if (instance.isMobile) {
        instance.currentOffsetHeader = instance.currentHeaderHeight;
        instance.translateHeader();
      }
    });
    this.header.addEventListener('ToBottom', (e) => {
      if (instance.isMobile) {
        instance.currentOffsetHeader = 0;
        instance.translateHeader();
      }
    });
  }

  defineMobile() {
    if (window.innerWidth >= 1280 && (this.isMobile || this.isMobile === null)) {
      this.isMobile = false;
    } else if (window.innerWidth < 1280 && !this.isMobile) {
      this.isMobile = true;
    }
  }

  bindScroll() {
    const instance = this;
    document.addEventListener('scroll', () => {
      if (!instance.isMobile) {
        return;
      }
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop < 0) {
        return;
      }

      const { lastScrollPosition } = instance;
      if (lastScrollPosition < scrollTop) {
        instance.translateHeaderTop(scrollTop - lastScrollPosition);
      } else {
        instance.translateHeaderBottom(lastScrollPosition - scrollTop);
      }

      instance.setLastScrollPosition(scrollTop);
    });
  }

  setLastScrollPosition(scrollY) {
    this.lastScrollPosition = scrollY;
  }

  translateHeaderTop(diff) {
    const availOffsetHeight = this.currentHeaderHeight;

    if (this.currentOffsetHeader < availOffsetHeight) {
      this.currentOffsetHeader += diff;
      if (this.currentOffsetHeader > availOffsetHeight) {
        this.currentOffsetHeader = availOffsetHeight;
      }

      this.translateHeader();
    }
  }

  translateHeaderBottom(diff) {
    if (this.currentOffsetHeader > 0) {
      this.currentOffsetHeader -= diff;
      if (this.currentOffsetHeader < 0) {
        this.currentOffsetHeader = 0;
      }
      this.translateHeader();
    }
  }

  defineHeaderHeight() {
    this.currentHeaderHeight = this.header.getBoundingClientRect().height;
  }

  translateHeader() {
    this.outer.style.transform = `translate3D(0, ${-this.currentOffsetHeader}px, 0)`;
  }

  bindHamburger() {
    const instance = this;
    this.hamburger.addEventListener('click', (e) => {
      e.preventDefault();
      instance.isOpened ? instance.closeMenu() : instance.openMenu();
    });
  }

  openMenu() {
    this.header.classList.add('_menu-opened');
    this.hamburger.classList.add('_opened');
    this.isOpened = true;
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    this.header.dispatchEvent(new Event('ToBottom'));
  }

  closeMenu() {
    this.header.classList.remove('_menu-opened');
    this.hamburger.classList.remove('_opened');
    this.isOpened = false;
    document.body.style.overflow = '';
    document.body.style.height = '';
    document.dispatchEvent(new Event('closeSearch'));
  }
}

onDomReady(() => {
  setTimeout(() => {
    const header = document.querySelector('[data-header]');
    if (header) {
      new Header(header);
    }
  }, timeoutDelay());
});