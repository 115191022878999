import axios from 'axios';
import { onDomChanges } from '../../components/dynamic/observer';

class ReviewLikes {
    constructor(element) {
        this.element = element;
        this.reviewId = element.dataset.reviewItem;
        this.quantityElement = element.querySelector('[data-review-diff]');
        this.actionLike = this.element.querySelector('[data-review-action-like]');
        this.actionDislike = this.element.querySelector('[data-review-action-dislike]');
        this.quantityNumberElement = this.quantityElement.querySelector('[data-review-diff-number]');
        this.quantity = parseInt(this.quantityNumberElement.innerHTML, 10);
        this.bind();
    }

    bind() {
        this.bindLike();
        this.bindDislike();
    }

    bindLike() {
        this.actionLike.addEventListener('click', (e) => {
            e.preventDefault();
            this.request(this.actionLike.href, this.reviewId);
            this.actionLike.classList.add('_active');
            this.actionDislike.classList.remove('_active');
        });
    }

    bindDislike() {
        this.actionDislike.addEventListener('click', (e) => {
            e.preventDefault();
            this.request(this.actionDislike.href, this.reviewId);
            this.actionLike.classList.remove('_active');
            this.actionDislike.classList.add('_active');
        });
    }

    async request(url, reviewId) {
        const result = await axios.post(url, { review_id: reviewId });
        if (this.quantity !== result.data.likes) {
            this.changeQuantity(result.data.likes);
        }
    }

    changeQuantity(quantity) {
        this.quantity = quantity;
        const sign = quantity > 0 ? '+' : '';
        this.quantityNumberElement.innerHTML = `${sign}${quantity}`;

        const colorClass = quantity === 0 ? '_gray' : (quantity > 0 ? '_green' : '_red');
        console.log(colorClass)
        this.quantityElement.classList.remove('_green', '_red', '_gray');
        this.quantityElement.classList.add(colorClass);
    }
}
onDomChanges(() => {
    const elements = document.querySelectorAll('[data-review-item]:not([data-initialized="true"])');
    elements.forEach((element) => {
        const reviewObject = new ReviewLikes(element);
    });
});